<template>
	<SectionHeader v-if="event && event.post" :image="event.post.picture" title="Veranstaltung" :page="event.post.title" />
	<div class="container">
		<EventItem :event="event" />
	</div>
</template>

<script>
import EventItem from '@/views/EventItemView.vue'
import SectionHeader from '@/views/SectionHeader'
export default {
	name: 'Veranstaltung',
	components: {
		EventItem,
		SectionHeader,
	},
	props: ["id"],
	computed: {
		event() {
			return this.$store.state.events.find(e => e.id == this.id);
		},
	},
	created() {
		this.$store.dispatch('loadEvents');
	},
	mounted() {
	}
};
</script>