<template>
	<div class="container px-4" v-if="event" :id="event.id">
		<div class="row gx-5 pt-2">
			<div v-if="event.canceled == true">
				<div class="alert alert-danger text-uppercase m-5 fs-5" role="alert">
					<i class="fad fa-ban"></i>
					Die Veranstaltung wurde abgesagt!
				</div>
			</div>
			<div class="col-xs-12 col-sm-6 col-lg-4">
				<div class="p-3">
					<div class="row text-end d-none d-sm-none d-md-block">
						<i class="fad fa-map-marked-alt fa-4x text-success"></i>
					</div>
					<span class="text-uppercase fw-light fs-5">Ort:</span><br/>
					<ul class="list-group list-group-flush">
						<li class="list-group-item">
							<span v-text="event.location.title" class="fw-bold text-uppercase" /><br />
							<span v-text="event.location.street" /><br />
							<span v-text="event.location.town" /><br />
						</li>
						<li class="list-group-item">
							<i :class="event.hasband ? 'fa fa-check text-success w-10' : 'fa fa-times text-danger w-10'"/>
							<i :class="event.hasband ? 'text-success' : 'text-danger'">Liveband</i>
						</li>
						<li class="list-group-item">
							<i :class="event.location.hascatering ? 'fa fa-check text-success w-10' : 'fa fa-times text-danger w-10'"/>
							<span :class="event.location.hascatering ? 'text-success' : 'text-danger'">Catering</span>
						</li>
						<li class="list-group-item">
							<i :class="event.location.hasparking ? 'fa fa-check text-success w-10' : 'fa fa-times text-danger w-10'"/>
							<span :class="event.location.hasparking ? 'text-success' : 'text-danger'">Kosenlose Parkplätze</span>
						</li>
						<li class="list-group-item">
							<i :class="event.location.hashandicapped ? 'fa fa-check text-success w-10' : 'fa fa-times text-danger w-10'"/>
							<span :class="event.location.hashandicapped ? 'text-success' : 'text-danger'">Rollstuhlgerecht</span>
						</li>
						<li class="list-group-item" v-if="event.corona_restriction == true">
							<span class="text-warning">Corona-Richtlinien <span class="text-uppercase" v-text="event.restriction_type"></span></span>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-xs-12 col-sm-6 col-lg-4">
				<div class="p-3">
					<div class="row text-end d-none d-sm-none d-md-block">
						<i class="fad fa-calendar-alt fa-4x text-success"></i>
					</div>
						<span class="text-uppercase fw-light fs-5">Datum:</span>
						<ul class="list-group list-group-flush">
							<li class="list-group-item">
								<span v-text="parseDate(event.date_start)" /> ab <span class="fw-bold" v-text="parseTime(event.date_start)" /> Uhr bis <br/>
								<span v-if="parseDate(event.date_start) != parseDate(event.date_end)" v-text="parseDate(event.date_end)" /> um <span class="fw-bold" v-text="parseTime(event.date_end)" /> Uhr
							</li>
						</ul>
				</div>
			</div>
			<div class="col-xs-12 col-sm-6 col-lg-4">
				<div class="p-3">
					<div class="row text-end d-none d-sm-none d-md-block">
						<i class="fad fa-address-card fa-4x text-success"></i>
					</div>
					<span class="text-uppercase fw-light fs-5">Veranstalter:</span><br/>
					<ul class="list-group list-group-flush">
						<li class="list-group-item">
							<span class="text-uppercase fw-bold" v-if="event.location && event.location.organizer" v-text="event.location.organizer" /><br/>
							<span v-if="event.location && event.location.phone" v-text="event.location.phone" /><br/>
							<span v-if="event.location && event.location.email" v-text="event.location.email" />
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="row gx-5 pt-2">
			<div class="col-sm-12">
				<div class="p-1">
					<div class="row border-bottom border-success text-end">
						<i class="fad fa-info fa-4x text-success"></i>
					</div>
					<h3 class="fw-light">Programm</h3>
					<p v-html="event.post.text" />
					<div v-if="event.entry && event.entry_amount && event.entry_amount_child">
						<div class="row border-bottom border-success text-end">
							<i class="fad fa-ticket fa-4x text-success"></i>
						</div>
						<h3 class="fw-light">Eintrittskarten</h3>
						<p class="fw-bold">Eintrittskarten sind erhältlich:</p>
						<ul v-for="type in event.ticket_type" :key="type">
							<span v-if="type == 'preorder'"><li>Als Vorbestellung beim KV Reutlingen</li></span>
							<span v-if="type == 'evening'"><li>An der Abendkasse</li></span>
						</ul>
						Erwachsene: {{ event.entry_amount }} Euro<br/>
						<span v-if="event.entry_amount_child">Schüler: {{ event.entry_amount_child }} Euro,<br/></span>
						Kinder unter 14 Jahren zahlen keinen Eintritt
					</div>

					<div v-if="event.hasband">
						<div class="row border-bottom border-success text-end">
							<i class="fad fa-music fa-4x text-success"></i>
						</div>
						<h3 class="fw-light">Band</h3>
						<router-link v-if="event.bandname" :to="{ name: 'Band', params: { id: event.bandname.id }}" v-text="event.bandname.name" class="fs-5 fw-light text-uppercase" />
						<span v-if="event.bandname.post && event.bandname.post.text" v-html="event.bandname.post.text"></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import moment from 'moment'
export default {
	name: 'EventItemView',
	props: ['event'],
	methods: {
		parseDate(date)
		{
			return moment(date).format('LL');
		},
		parseTime(date)
		{
			return moment(date).format('HH:mm');
		}
	},
	mounted () {
		window.scrollTo(0, 0)
	}
};
</script>